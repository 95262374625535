import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate, BrowserRouter as Router, Routes, Route, Link, NavLink } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll'

import './overhead/default.scss';
import './overhead/main.scss';
import './overhead/fonts.scss';
import './view_auxiliary/nav.scss';
import './view_auxiliary/footer.scss'

import Account from './view_account/account'
import Home from './view_home/home'
import Cart from './view_onboarding/cart'
import SignUp from './view_onboarding/signup'
import SignIn from './view_onboarding/signin'
import NotFound from './view_onboarding/not_found'
import ResetPassword from './view_onboarding/reset_password'
import PaymentCallback from './view_onboarding/payments'
import EmailConfirmation from './view_onboarding/email_confirmation'

import { ToS, Disclaimer, Privacy } from './view_auxiliary/legal'

const Nav = ({ isMobile, token, user, logout, refHero, refFeatures, refPricing, refAbout}) => {
    const [defaultAccountView, setDefaultAccountView] = useState('settings');

    // for mobile only
    const [showNav, setShowNav] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (user && user.subscriptions && user.subscriptions.length > 0) {
            setDefaultAccountView(user.subscriptions[0]);
        }
    }, [])

    // useEffect(() => {

    // }, [location]);

    const scrollTo = (ref) => {
        window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })
        setShowNav(false)
    }

    return (<>
        <div className={(!showNav && isMobile) ? 'nav-container mobileToggle' : 'nav-container' }>
            {(!showNav && isMobile) ?
                <div className="nav">
                    <div className="nav-link" onClick={() => setShowNav(true)}>&#9776; &nbsp;Open Navigation</div>
                </div>
            :
            <div className="nav">
                {(location.pathname == '/') ? <>
                    <ScrollLink to="hero"
                        smooth={true}
                        spy={true}
                        duration={500}
                        activeClass='active'
                        className="nav-link">Home</ScrollLink>
                    <ScrollLink to="features"
                        smooth={true}
                        spy={true}
                        duration={500}
                        activeClass='active'
                        className="nav-link">Features</ScrollLink>
                    <ScrollLink to="pricing"
                        smooth={true}
                        spy={true}
                        duration={500}
                        activeClass='active'
                        className="nav-link">Pricing</ScrollLink>
                    <ScrollLink to="about" 
                        smooth={true}
                        spy={true}
                        duration={500}
                        activeClass='active'
                        className="nav-link">About</ScrollLink>
                    </>
                    :
                    <NavLink to="/" 
                        className="nav-link" 
                        onClick={() => setShowNav(false)}
                        activeClassName="active">Home</NavLink>
                }
                    
                {(!token || token == '') ? <>
                    <NavLink to="/signin" 
                        className="nav-link" 
                        activeClassName="active"
                        onClick={() => setShowNav(false)}>Sign In</NavLink>
                    <NavLink to="/signup"
                        className="nav-link" 
                        activeClassName="active"
                        onClick={() => setShowNav(false)}>Sign Up</NavLink>
                </>
                :<> 
                    <NavLink to="/account"
                        className="nav-link" 
                        activeClassName="active" 
                        onClick={() => setShowNav(false)}>My Account</NavLink>
                    <Link to="/" 
                        className="nav-link" 
                        onClick={() => {
                            logout(); 
                            setShowNav(false);}}>Logout</Link>
                </>}
                
                {(process.env.REACT_APP_ENV == 'development') && <>
                    <NavLink to="/cart" 
                        className="nav-link" 
                        activeClassName="active"
                        onClick={() => setShowNav(false)}>Cart</NavLink>
                </>}

                {(showNav && isMobile) &&
                    <div className="nav-link" onClick={() => setShowNav(false)}>&#x2715; &nbsp;Close Navigation</div>}
            </div>}
        </div>
    </>);
}

const Footer = () => {
    const location = useLocation();
    const [show_cond, setShowCond] = useState(false)
    const [abs_cond, setAbsCond] = useState(false)

    useEffect(() => {
        let loc = location.pathname;
        setAbsCond(loc !== '/') 
        setShowCond(loc == '/' )
    },[location])

    return (<>
        {show_cond &&  
        <div className={ abs_cond ? "footer-container absolute" : "footer-container"}>
            <div className="footer-rows">
                <Link className="footer-links" to="/tos">Terms of Service</Link>
                <Link className="footer-links" to="/privacy">Privacy Notice</Link>
                <Link className="footer-links" to="/disclaimer">Disclaimer</Link>
            </div>
            <div className="footer-rows">
                <div className="footer-text"><span className="material-icons-outlined">copyright</span>&nbsp; 2021 Hey Cadence</div>
                {/*<div className="footer-text made">Made in Vancouver, Canada</div>*/}
            </div>
        </div>
        }
    </>)
}

// Default behavior saves scroll position on route change. This reset to scroll to top
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppContent = () => {
    let stored_user = {}
    let stored_token = ''

    // variables declared at App component, then passed to both nav and home components. Home component then sets ref={myRef} and nav component calls myRef.current.scrollIntoView. Smooth scrolling is handled by scrolling property in css: scroll-behavior: smooth.
    const refHero = useRef();
    const refFeatures = useRef();
    const refPricing = useRef();
    const refAbout = useRef();

    const location = useLocation();
    let navigate = useNavigate();

    try {
        // console.log('trying: retrieve localStorage data')
        let temp_token = window.localStorage.getItem('token')
        let temp_user = window.localStorage.getItem('user')
        // console.log('trying: localStorage items accessed')

        // JSON.parse('abc') will error, 'null' string needs to be parsed
        stored_token = temp_token == 'null' ? JSON.parse(temp_token) : temp_token;
        stored_user = JSON.parse(temp_user)
        
        // console.log('stored user', stored_user, stored_token);
    } catch {
        // console.log('catch: failed to retrieve localStorage data, removing...');
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('token')
    }

    const [user, setUser] = useState(stored_user);
    const [token, setToken] = useState(stored_token);

    let cond1 = !token
    let cond2 = location.pathname.includes('account')
    let cond3 = location.pathname.includes('cart')

    if ((cond1 && cond2) || (cond1 && cond3)) {
        // console.log('Attempting to reach authenticated pages without token');
        navigate('/signin');
    }

    useEffect(() => {
        // console.log('updating local storage user and token');
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
    }, [user, token])

    // Check if mobile
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        setIsMobile(width <= 479)
    }, [width])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    function logout() {
        setToken(null);
        setUser(null);
        navigate('/');
    }

    // Most refs are unused and have been replaced by react-scroll
    return (<>
        <ScrollToTop />
        <Nav isMobile={isMobile} token={token} logout={logout} user={user} refHero={refHero} refFeatures={refFeatures} refPricing={refPricing} refAbout={refAbout} />
        <Routes>
            <Route exact path="/" element={<Home token={token} refHero={refHero} refFeatures={refFeatures} refPricing={refPricing} refAbout={refAbout} />} />
            <Route path="/account/*" element={<Account isMobile={isMobile} token={token} user={user} setToken={setToken} setUser={setUser} logout={logout} />} />
            <Route exact path="/signin" element={<SignIn setToken={setToken} setUser={setUser} />} />
            <Route exact path="/signup" element={<SignUp setToken={setToken} setUser={setUser} isMobile={isMobile} />} />
            <Route exact path="/cart" element={<Cart token={token} user={user} setUser={setUser} />} />
            <Route exact path="/reset_pass" element={<ResetPassword token={token} setToken={setToken}/>} />
            <Route exact path="/not_found" element={<NotFound token={token} setToken={setToken}/>} />
            <Route exact path="/payment_confirmation" element={<PaymentCallback token={token} setToken={setToken} />} />
            <Route exact path="/email_confirmation" element={<EmailConfirmation />} />
            <Route exact path="/tos" element={<ToS />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
        </Routes>
        <Footer />
    </>)
}
const App = () => (
    <div className="masterflex">
        <Router>
            <AppContent />
        </Router>
    </div>
)
// <Link to="/"><div className="nav-logo"></div></Link>
export default App;

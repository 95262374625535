import React, {useState} from 'react';
import { Element } from 'react-scroll'

import './section_about.scss'
// import SectionPricing from './pricing.js'

let faqs = [
{hide: true,
    q: "I don't trade very much, how is this service useful for me?", 
    a: "That's OK! It was not our intent for this to be used for trading purposes. Rather, we like to think of Hey Cadence as an analyst or secretary to whom you might ask, \"How are my holdings doing this morning?\" It's an easy way to keep an eye on your stock holdings without expending much effort. If you find this an appealing idea and don't already have an analyst or secretary, it might be worthwhile trying out."
}, {   hide: true,
    q: "I'm a frequent trader, how is this service useful for me?", 
    a: "Hey Cadence is generally not used on tickers you frequently trade, as you'd want much lower latency. This designed for your portfolio companies that you hold for longer durations, as it aggregates all news events occuring in the day, or week, to inform the general state of your holdings on an on-going basis and help reduce the chances of missing major news events."
}, { 
    hide: true,
    q: "Why don't I have news on Monday mornings?", 
    a: "Hey Cadence rounds up and summarize news that happened during the day so you can have them available the following morning. As Sunday precedes Monday, Monday does not have news. We round up news this way to limit disturbances from notifications throughout the day so you can focus on the task at hand."
}, {hide: true,
    q: "Why don't some of my companies have any news today?", 
    a: "This is perfectly normal, as not all companies have news every day. We also actively filter out most opinion pieces (such as where an analyst might think the price would go) to limit summaries to material changes in the company's operations that are reported by the news outlets."
}]

const SectionAbout = ({scrollRef}) => {
    const [faq, setFaq] = useState(faqs);
    const [activ, setActiv] = useState(-1);

    function expand(i) {
        // let faq_copy = [...faq];
        // faq_copy[i].hide = !faq[i].hide;
        // setFaq(faq_copy);
        // console.log(i);
        if (activ == i) {
            setActiv(-1)
        } else {
            setActiv(i)
        }
    }

    return (
        <Element name="about" className="section-feature about">
            <div className="about-content">
            <div className="feature-header about">FAQ&nbsp; ✍️</div>
            {faq.map((el, i) => <div className="question-container" key={i}>
                <div className="faq-question" onClick={() => expand(i)}>
                { (activ != i) ? 
                <span className="material-icons-outlined about-arrow">expand_more</span>
                :
                <span className="material-icons-outlined about-arrow">expand_less</span>
                }
                <span>{el.q}</span></div>
                { (activ == i) && 
                    <div className="faq-answer">{el.a}</div>
                }
            </div>)}
            <br></br>
            <br></br>
            <div className="feature-header about">About Us&nbsp; &#128075;</div>
            <div className="about-us-body">
            Hello! Good to see that you’ve made it this far.
            <br></br><br></br>
            We made Hey Cadence in the fall of 2021 as a way to stay updated on our portfolios with as little effort as possible. We wanted news updates on our own time — while we went on our runs or went about brushing our teeth in the morning — and we wanted concision. 
            <br></br><br></br>
            Basically Hey Cadence provides a little news snippet every day (or week), usually less than 5 minutes, to help you keep pace with the general state of your holdings. It provides TLDR summaries so you'd have these events on your radar and have option to look into it further. We’ve been using it amongst ourselves for a while now and haven’t been able to make do without it, so we decided to make it into a service available to others. 
            <br></br><br></br>
            We love hearing from our users, so feel free to  <a href="mailto:hello@heycadence.com">drop us a line</a>. We can't promise to reply to all your emails, but we do read all of them.
            </div>
           </div>
        </Element>
    )
}

export default React.memo(SectionAbout);
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

// TODO log user out / delete current token after user changes email
// this way you don't need to  set the token.
const ViewSettings = ({token, user, setToken, setUser}) => {
    const navigate = useNavigate()
    const emailRef = useRef(null);
    const oldPwd   = useRef(null);
    const newPwd1  = useRef(null);
    const newPwd2  = useRef(null);

    const [daysub, setDaysub] = useState(false);
    const [weeksub, setWeeksub] = useState(false);
    // const [newEmail, setNewEmail] = useState(user.email);

    const [doNotEmail, setDoNotEmail] = useState(false);
    const [confirmationSent, setConfirmationSent] = useState(false);

    const [changeSub, setChangeSub] = useState(false);
    const [changePwd, setChangePwd] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [changeDelete, setChangeDelete] = useState(false);
    const [changeDoNotEmail, setChangeDoNotEmail] = useState(false);

    const [error, setError] = useState('');
    const [saved, setSaved] = useState(false);

    // update displayed subscription on mount
    useEffect(() => {
        if (!user) return;

        fetch(`${api_endpoint}?intent=user.get&token=${token}`)
            .then(res => res.json())
            .then(data => {
                // error handling for data
                if (data.status !== 'success') return;

                // console.log(data.user);
                setUser(data.user);
                setDoNotEmail(data.user.do_not_email); // sets initial value
            });

        if (user.subscriptions && user.subscriptions.includes('daily')) setDaysub(true);
        if (user.subscriptions && user.subscriptions.includes('weekly')) setWeeksub(true);
    }, [])

    useEffect(() => {
        if (changeSub ||  changePwd || changeEmail || changeDelete) {
            setSaved(false)
        }
    }, [changeSub, changePwd, changeEmail, changeDelete])

    function resolveSave() {
        setSaved(true)
        setError('')
        setChangeSub(false);
        setChangePwd(false);
        setChangeEmail(false);
    }

    async function handleSubmit() {
        // update subscription
        if (changeSub) {
            // console.log('Cancelling subscriptions')
            let new_sub = [];
            // unnecessary copy, i know. 
            new_sub = daysub ? [...new_sub, 'daily'] : new_sub;
            new_sub = weeksub ? [...new_sub, 'weekly'] : new_sub;
            // console.log(new_sub);
            let payload = {
                intent: 'subscription.update',
                token: token,
                new_subscription: new_sub
            }
            let res = await fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)});
            let data = res.json()
            // console.log(data);
            if (data.token) {
                setToken(data.token);
                window.localStorage.setItem('token', data.token);
            }

            let user_copy = user;
            user_copy.subscriptions = new_sub;
            setUser(user_copy);
            resolveSave();
        }
        // update password
        if (changePwd) {
            if (newPwd1.current.value.length < 8) return setError('Your new password should be at least 8 characters');
            if (newPwd1.current.value !== newPwd2.current.value) return setError('Passwords do not match');

            let payload = {
                intent: 'password.update',
                token: token,
                old_password: oldPwd.current.value,
                new_password: newPwd1.current.value
            }
            let res = await fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)});
            let data = res.json()
            // console.log(data);
            resolveSave();
        }
        // update email
        if (changeEmail) {
            if (!validateEmail(emailRef.current.value)) return setError('Please provide a valid email')

            let payload = {
                intent: 'email.update',
                token: token,
                new_email: emailRef.current.value
            }
            try {
                let res = await fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)});
                let data = res.json();

                // console.log(data);
                let user_copy = {...user}; // '=' won't trigger useEffect
                user_copy.new_email = emailRef.current.value;
                emailRef.current.value = user.email; // set this back since new email hasn't been verified.
                setUser(user_copy);
                resolveSave();
            } catch (e) {
                console.error(e);
            }
        }
    }

    // Reset displayed value to original values if the user has changed sub but chose not to keep them.
    function cancelChangeSub() {
        if (user.subscriptions && user.subscriptions.includes('daily')) setDaysub(true);
        if (user.subscriptions && user.subscriptions.includes('weekly')) setWeeksub(true);
        setChangeSub(false)
    }

    function markForDeletion() {
        let payload = {
            intent: 'user.delete',
            token: token
        }
        fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.status == 'success') {
                console.log('deletion marked success')
            } else {
                setError('An error occurred, unable to update email preferences at this time');
            }
        })
    }

    function updateDoNotEmail(val) {
        if (val == user.do_not_email) {
            return setChangeDoNotEmail(false);
        };

        let payload = {
            intent: 'email.optout',
            do_not_email: val,
            token: token
        }
        fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.status == 'success') {
                setDoNotEmail(val);
                setChangeDoNotEmail(false);    
            } else {
                setError('An error occurred, unable to update email preferences at this time');
            }
        })
    }

    function resendConfirmation() {
        let payload = {
            intent: 'email.resend.confirmation',
            token: token
        }
        setConfirmationSent(true);
        fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            // Note: Response takes too long for some reason, so I set the confirmation sent toggle immediately instead
            // console.log(data);
        })
    }

    const manageBilling = async () => {
        // console.log('creating customer portal');
        let payload = {
            intent: 'subscription.manage',
            stripe_customer_id: user.stripe_customer_id, 
            token : token
        }
        let res = await fetch(api_endpoint, {method : 'POST', body: JSON.stringify(payload)})
        // console.log(res);
        let data = await res.json();

        // console.log(data)
        window.location = data.url;
    }

    // important: need to stop render if user is not defined, else it will throw error trying to access
    // undefined user object
    if (!user) return null;

    return (<> 
        <div className="account-section-header">Settings</div>
        <div className="account-settings-body">

            {/* ----- Change Email Begins ----- */}
            <div className="account-info-item">
                <div className="account-input-label">Email</div>
                {/* Use default value here bc in editMode, value={user.email} cannot be changed */}
                <input type='text'
                    autoComplete='off'
                    ref={emailRef}
                    readOnly={!changeEmail}
                    onFocus={e => e.target.select()}
                    className={changeEmail ? "account-info-input active" : "account-info-input unselectable"}
                    defaultValue={user.email} />

            {(user.new_email && !changeEmail) && <>
                <div className="account-info-item">
                    <div className="account-input-label">Updated Email</div>
                    <input type='text'
                        readOnly={true}
                        className="account-info-input"
                        value={user.new_email} />
                </div>
                <div className="account-info-item">
                    {confirmationSent ? 
                        <span className="account-basic-info">Confirmation email has been sent! It may take up to 15 minutes for the email to arrive</span>
                        :<>
                        <span className="account-basic-info">To ensure you're always able to access your account,  "Updated Email" won't be activated until you've clicked on the verification link in the confirmation email. Until then, use your previous email to sign in.</span><span className="account-basic-inline-btn" onClick={resendConfirmation}>Resend Confirmation Email</span>
                        </>}
                </div>
                </>
            }

            {!changeEmail ? <>
                {/*<br></br>*/}
            <div className="account-basic-btn settings-right"
                onClick={e => {setChangeEmail(true); emailRef.current.focus(); }}>Change</div>
            </>:
            <>
            <div className="account-basic-info block">To save, scroll down and press "Save Changes" aftering entering your new email</div>
            <div className="account-basic-btn cancel"
                onClick={e => {setChangeEmail(false); }}>Cancel change</div>
            </>}

            {(!user.confirmed_email && !changeEmail && !user.new_email) &&
                <div className="account-info-item no-border">
                    {confirmationSent ? 
                    <span className="account-basic-info">Confirmation email has been sent! It may take up to 15 minutes for the email to arrive</span>
                    :<>
                        <span className="account-basic-info">Please check your inbox to verify your email</span>
                        <span className="account-basic-inline-btn" onClick={resendConfirmation}>Resend Confirmation Email</span>
                    </>}
                </div>
            }
            
            </div>

            {/* ----- Change Password Begins ----- */}
            <div className="account-info-item">
                <div className="account-input-label oneline">Password</div>
            {changePwd && <>
                <div className="account-basic-info">To save, scroll down and press "Save Changes" aftering entering your new password</div>
                <div className="account-info-item change-pwd">
                    <div className="account-input-label">Old password</div>
                    <input type='password'
                        autoComplete='off'
                        className="account-info-input"
                        ref={oldPwd} />
                </div>
                <div className="account-info-item change-pwd">
                    <div className="account-input-label">New password</div>
                    <input type='password'
                        autoComplete='off'
                        className="account-info-input"
                        ref={newPwd1} />
                </div>
                <div className="account-info-item change-pwd">
                    <div className="account-input-label">Confirm new password</div>
                    <input type='password'
                        autoComplete='off'
                        className="account-info-input"
                        ref={newPwd2} />
                </div>
                </>
            }
            {!changePwd ? 
            <div className="account-basic-btn settings-right"
                onClick={() => setChangePwd(true)}>Change</div>
                : 
            <div className="account-basic-btn cancel"
                onClick={() => setChangePwd(false)}>Cancel change</div>
            }
            </div>
            {/* ----- Subscriptions Begins ----- */}

            <div className="account-info-item">
                <div className="account-input-label oneline">Subscriptions & Billing</div>
                <div className="account-basic-btn settings-right" onClick={manageBilling} >Manage</div>
            </div>

            {/* ----- Email Begins ----- */}

            <div className="account-info-item">
                <div className="account-input-label oneline">Do Not Email</div>
                <div className="account-input-label oneline">{doNotEmail ? 'Enabled' : 'Disabled'}</div>
                {!changeDoNotEmail ?<>
                    {/*<br></br>*/}
                    <div className="account-basic-btn settings-right" onClick={() => setChangeDoNotEmail(true)} >Update</div>
                    </>
                    :<>
                <div className="account-basic-info">If you enable Do Not Email, you will no longer receive any emails about your summaries, and can only access them by visiting our website</div>
                <div className="account-basic-btn cancel"
                    onClick={() => updateDoNotEmail(false)}>No, I do not want to enable Do Not Email</div>
                <br></br>
                <div className="account-basic-btn warn"
                    onClick={() => updateDoNotEmail(true)}>I understand, and I want to enable Do Not Email</div>
                </>}
            </div>
            {/* ----- Delete Account Begins ----- */}
            <div className="account-info-item">
                <div className="account-input-label oneline">Account Deletion</div>

                {!changeDelete ?
                <div className="account-basic-btn settings-right"
                    onClick={() => setChangeDelete(true)}>Delete Account</div>
                :
                <>
                <div className="account-basic-info">Your account will be marked for deletion for the next deletion cycle. Once your account is deleted, all its associated data will no longer be recoverable</div>
                
                <div className="account-basic-btn cancel"
                    onClick={() => setChangeDelete(false)}>No, I do not want to delete my account</div>
                <br></br>
                <div className="account-basic-btn warn"
                    onClick={markForDeletion}>I understand, and I want to delete my account</div>
                </>
                }
            </div>

            <div className="account-settings-msg error">{error}</div>
            {saved && 
                <div className="account-settings-msg success">Changes have been successfully saved</div>
            }
            {(changePwd || changeEmail || changeSub) && 
                <div className="portfolio-next-btn" onClick={handleSubmit}>Save Changes</div>
            }
        </div>
    </>)
}

export default React.memo(ViewSettings)

// <div className="account-basic-btn settings-right" onClick={() => setChangeSub(true)} >Cancel Subscription</div>

                // {(user.subscriptions && !user.subscriptions.includes('daily') && user.subscriptions && !user.subscriptions.includes('weekly')) ? 
                //     <div className="account-basic-info">You do not have any active subscriptions.</div> 
                //     : <>
                //     <div className={changeSub ? "" : "unselectable"}>
                //         {(user.subscriptions && user.subscriptions.includes('daily')) && 
                //             <div className={daysub === true ? "subscription-option active" : "subscription-option" }
                //                 onClick={() => setDaysub(!daysub)}>
                //                 {daysub ?
                //                     <span class="material-icons-outlined">check_box</span>
                //                     : 
                //                     <span class="material-icons-outlined">check_box_outline_blank</span>
                //                 }
                //                 <div className="subscription-option-content">
                //                     <div className="subscription-option-title">Daily summaries</div>
                //                     <div className="subscription-option-descr">Every weekday</div>
                //                 </div>
                //             </div>
                //         }
                //         {(user.subscriptions && user.subscriptions.includes('weekly')) && 
                //             <div className={weeksub === true ? "subscription-option active" : "subscription-option" }
                //                 onClick={() => setWeeksub(!weeksub)}>
                //                 {weeksub ?
                //                     <span class="material-icons-outlined">check_box</span>
                //                     : 
                //                     <span class="material-icons-outlined">check_box_outline_blank</span>
                //                 }
                //                 <div className="subscription-option-content">
                //                     <div className="subscription-option-title">Weekly summaries</div>
                //                     <div className="subscription-option-descr">Every Sunday</div>
                //                 </div>
                //             </div>
                //         }
                //     </div>
                //     {!changeSub ? 
                //     <div className="account-basic-btn settings-right" onClick={manageBilling} >Manage Subscriptions</div>
                //         : <>
                //         <div className="account-basic-info">To cancel your subscription, click to uncheck, then click "Save Changes." It is highly recommended that you download any audio files you wish to keep, as upon cancelling, we can no longer guarantee access to your previous summaries corresponding to the cancelled subscription.</div>
                        
                //         <div className="account-basic-btn settings-right cancel"
                //             onClick={cancelChangeSub} >I do not want to cancel my subscription</div>
                //         <br></br>
                //     </>}
                // </>}
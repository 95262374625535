import React, { useState, useEffect, useRef } from 'react';
import PlayableItem from './../components/playable_item'

const ViewEpisodes = ({ token, user, frequency, subscriptions }) =>{
    const [episodes, setEpisodes] = useState([]);

    useEffect(() => {
        if (!user) return;
        getEpisodeData();
    }, [])

    useEffect(() => {
        // console.log('change in frequency detected')
        getEpisodeData();
    }, [frequency])

    const getEpisodeData = () => {        
        let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=episode.get&frequency=${frequency}&token=${token}`;
        // console.log(url)
        fetch(url, {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            // console.log('subview episodes', data);

            if (!data.episodes) {
                // invokes message about not having episodes
                return setEpisodes([]);
            }

            let res = data.episodes;
            // news_period is given time-zone agnostic
            let options = { timeZone: 'UTC', weekday: "long", year: "numeric", month: "short",  day: "numeric"};

            for (let i = 0; i < data.episodes.length; i++) {
                let date = new Date(data.episodes[i].episode_date);  
                res[i].display_date = date.toLocaleDateString("en-us", options);
                res[i].loadToPlayer = false;
                res[i].audio_url = `https://cadence-${process.env.REACT_APP_ENV}-audio-episodes-${frequency}.s3.us-west-2.amazonaws.com/${res[i].audio_key}`
            };

            setEpisodes(res);
        })
        .catch(e => {
            console.error(e);
        })
    }

    const loadPlayer = (idx) => {
            const episodes_copy = [...episodes];
            episodes_copy[idx] = {...episodes[idx], loadToPlayer:true};
            setEpisodes(episodes_copy);
        }

    if (!user) return null;

     return (
        <div className="scrollable-y">
            {(episodes && episodes.length > 0) ? episodes.map((episode, index) => 
                <PlayableItem item={episode} />   
            ) : 
                <div className="account-start-msg">No summaries yet. Newly created accounts may take until the end of the day (or week if you're subscribed weekly) to generate a new summary. You will be notified via email when there is news.</div>
            }
        </div> // need to wrap this in one div or else won't work
    )
}

export default React.memo(ViewEpisodes)
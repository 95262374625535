import React, { useState, useEffect, useRef } from 'react';
import tos from './legal_tos'
import disclaimer from './legal_disclaimer'
import privacy from './legal_privacy'
import './legal.scss'

const template_tos = { __html: tos };
const template_disclaimer = { __html: disclaimer };
const template_privacy = { __html: privacy };

const ToS = () => {
    return (<>
        <div className="legal-container">
            <div dangerouslySetInnerHTML={template_tos} />
        </div>
        <div className="legal-bg"></div>
    </>)
}

const Disclaimer = () => {
    return (<>
        <div className="legal-container">
            <div dangerouslySetInnerHTML={template_disclaimer} />
        </div>
        <div className="legal-bg"></div>
    </>)
}

const Privacy = () => {
    return (<>
        <div className="legal-container">
            <div dangerouslySetInnerHTML={template_privacy} />
        </div>
        <div className="legal-bg"></div>
    </>)
}

const memoToS = React.memo(ToS);
const memoDisclaimer = React.memo(Disclaimer);
const memoPrivacy = React.memo(Privacy);

export { memoToS as ToS, memoDisclaimer as Disclaimer, memoPrivacy as Privacy }
import React, {useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import Symbols from './../symbols_2021-09-26.js'

import './search.scss';

const fuse = new Fuse(Symbols.Symbols, {
  // includeScore: true,
  distance: 12,
  // ignoreLocation: true,
  // ignoreFieldNorm: false,
  keys: [
    {name: 's', weight: .7}, 
    {name: 'n', weight: .3}
  ]
});



const Search = ({onSubmit, clearOnSubmit, blurOnSubmit, classNames, searchPlaceholder = 'Search here...', options = [], readOnly = false}) => {

    // let readonly = (typeof readOnly != 'undefined') ? readOnly : false;
    const [searchTerm, setSearchTerm] = useState('')
    const [results, setResults] = useState([])
    const [selected, setSelected] = useState(0)
    const [focus, setFocus] = useState(false)
    const [editable, setEditable] = useState(!readOnly);
    const inputRef = useRef();

  useEffect(() => {
    if (options.length > 0) {
        setResults(options)
    }
  }, [])

  useEffect(() => {
    if (!editable) return;

    const delayDebounceFn = setTimeout(() => {
      setSelected(0)
      setResults(fuse.search(searchTerm, {limit: 6}))
    }, 100)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // console.log('submit')
      // console.log(results[selected].item.Symbol);
      handleSubmit();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault(); // prevents going to the start of line
      let sel = (selected === 0) ? 0 : selected - 1;
      setSelected(sel)
    } else if (e.key === 'ArrowDown') {
      let sel = (selected === 5) ? 5 : selected + 1;
      setSelected(sel)
    }
  }

  const handleBlur = () => {
    setFocus(false);
  }

  const handleFocus = () => {
    setFocus(true);
  }

  const handleClick = (e, idx) => {
    e.preventDefault();
    setSelected(idx);
    handleSubmit()
  }

  const handleSubmit = () => {

    inputRef.current.value = results[selected].item.s.split('-')[1];

    onSubmit(results[selected].item);

    if (!editable) return;

    if (clearOnSubmit) {
      inputRef.current.value = ''
    }
    if (blurOnSubmit) {
        // console.log('blur detected');
        setTimeout(()=> {
          manualBlur()
        }, 100)
    } else {
      // indicate something happened by closing search results
      setResults([])
    }
  }

  const manualBlur = () => {
    document.activeElement.blur()
  }

  // onMouseOver={(index) => handleHover(index)}
  const handleHover = (idx) => {
    setSelected(idx);
  }

  return (
    <>
      <div className="search-area">
        <div className={focus ? classNames + " search-input focused" : classNames + " search-input"}>
          <input
              type='text'
              autoComplete='off'
              className=" search-ticker"
              placeholder={searchPlaceholder}
              ref={inputRef}
              onClick={() => handleFocus()}
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              onKeyDown={e => handleKeyDown(e)}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
        <div className={(results.length === 0 || !focus) ? "search-results-container hide" : "search-results-container"}>
          {results.length > 0 && 
            results.map((result, index) => (
            <div key={index}
              onMouseOver={() => handleHover(index)}
              onMouseDown={(e) => handleClick(e, index)}
              className={(index === selected) ? "search-result active" : "search-result"}>
              <span className="search-symbol" key={result.item.s}>{result.item.s.split('-')[1]}</span>
              <span className="search-name">{result.item.n}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Search;
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './entry.scss';
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function randInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const sendMsgs = ['Loading']

// Login / signup.
const SignIn = ({setToken, setUser}) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [buttonName, setButtonName] = useState('Sign In');
    const [activeView, setActiveView] = useState('login');

    function renderSwitch(activeView) {
        switch (activeView) {
            case 'login':
                return (<div className="entry-container">
                        <div className="entry-title">Sign In</div>
                        <div className="entry-error signin">{error}</div>
                        <div className="entry-item">
                            <div className="entry-label">Email</div>
                            <input type="text" 
                                placeholder="cadence@example.com"
                                className="entry-field"
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="entry-item">
                            <div className="entry-label">Password</div>
                            <input type="password" 
                                className="entry-field" 
                                key={activeView}
                                onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="entry-link" onClick={() => setActiveView('forgot_pass')}>Forgot password</div>
                        <div className="entry-btn"
                            onClick={submit}>{buttonName}</div>
                        <div className="entry-change">Don't have an account? <Link to="/signup" className="link">Sign up</Link></div>
                    </div>)
                break;
            case 'forgot_pass':
                return (<div className="entry-container">
                    <div className="entry-back" onClick={() => setActiveView('login')}><span className="material-icons-outlined">arrow_back</span>&nbsp; Back to Sign In</div>
                    <div className="entry-title">Forgot Password</div>
                    <div className="entry-info">Fret not! Enter the email associated with your account, and we'll send you a link to reset your password</div>
                    <div className="entry-error signin">{error}</div>
                    <div className="entry-item">
                        <div className="entry-label">Email</div>
                        <input type="text" 
                            placeholder="cadence@example.com"
                            className="entry-field"
                            key={activeView}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="entry-btn" onClick={requestPasswordReset}>Send Password Reset Link</div>
                </div>)
                break;

            case 'request_sent':
                return (<div className="entry-container">
                    <div className="entry-title">Reset Password Link Sent!</div>
                    <div className="entry-info">If your email is associated with an account, you should expect to receive an email in the next 15 minutes. Check your spam folder in case the email was delivered there instead of your inbox.</div>
                    <div className="entry-btn" onClick={() => setActiveView('login')}>Go back</div>
                </div>)
                break;
        }
    }

    const submit = () => {
        if (!validateEmail(email)) return setError('Please provide a valid email')
        if (password.length < 8) return setError('Please provide a valid password');

        setButtonName(sendMsgs[randInt(0, sendMsgs.length)])

        const payload = {intent: 'user.login', email: email, password: password}

        fetch(api_endpoint, {method:'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            console.log(data);
            if (data.status === 'success') {
                setToken(data.token)
                setUser(data.user)
                // POSSIBLE ISSUE: So far this has not been a problem, but the above setter method is async, so it's conceivable that 
                // you'd be redirected from the account page if the setter method did not propagate

                let frequency = (data.user.subscriptions > 0) ? data.user.subscriptions[0] : 'daily';
                navigate('/account/updates/'+frequency+'/episodes'); 
                // These setters have been relocated to App.js watching user and token states. 
            } else {
                setButtonName('Sign In')
                if (data.details === 'login_failed')
                    setError('An error occurred: Invalid email or password');
                else 
                    setError('An unknown error occurred');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    async function requestPasswordReset() {
        if (!validateEmail(email)) return setError('Please provide a valid email')
        setActiveView('request_sent');
        let res = await fetch(api_endpoint + `?intent=password.forgot&email=${email}`)
        // console.log(res)
    }

    return (
    <div className="entry-cover">
        <div className="entry-content signin">
            <div className="entry-logo"></div>
            {renderSwitch(activeView)}
        </div>
    </div>
    )
}

export default React.memo(SignIn);

// <div className="bg-flat"></div>

// Footnote: use of key inside input forces re-render. Without key the input element is kept when changing to forgot_password view, but exposes user's password. See: https://stackoverflow.com/questions/30146105/react-input-defaultvalue-doesnt-update-with-state
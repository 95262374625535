import './hero.scss';
import React, { useState, useEffect, useRef } from 'react';
import {BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Element } from 'react-scroll'

// import img from './media/bg4.jpg';
function Hero({scrollRef}) {

  return (
    <Element name="hero" className="hero">
      <div className="hero-header">
      <div className="hero-lead">Short & Sweet</div>
      <div className="hero-main">Daily Audio TLDR News <br></br> for Your Portfolio</div>
      <div className="hero-sub">Summarized <span className="hero-hl">Daily</span> & <span className="hero-hl">Weekly</span></div>
      <Link to="/signup"><div className="hero-btn main"><span>Start Now</span> <span className="material-icons-outlined">chevron_right</span></div></Link>
       <a href="mailto:sales@heycadence.com" className="hero-btn contact hoverable">Contact Sales <span className="material-icons-outlined">chevron_right</span></a>
      </div>
       
    </Element>
  );
};

// <div className="hero-subheader">
//           <div className="hero-numero">1</div> Add your portfolio &#8674; 
//           <div className="hero-numero">2</div> Get scheduled news summaries
//         </div>

export default React.memo(Hero);
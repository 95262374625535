import React, { useState, useEffect, useRef, useMemo } from 'react';
import Infotip from './../components/tooltip.js'
import {useNavigate, useLocation, BrowserRouter as Router, Switch, Routes, Route, NavLink } from "react-router-dom";

import ViewSettings from './account_settings'
import ViewPortfolio from './account_portfolio'
import ViewUpdates from './account_updates'

import './account.scss'

const Account = ({isMobile, token, user, setToken, setUser, logout}) => {
    let navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        // console.log('account token' , token);
        // console.log('account user' , user);
        // console.log('pathname', pathname);
        // Must check if user object exists, otherwise will return blank page. E.g.
        if (!token || token == '') { 
            return navigate('/signin');
        }

        const cond = user.subscriptions && (user.subscriptions.length == 1) && user.subscriptions.includes('weekly')
        // auto redirect from navigation
        if (pathname == '/account') {
            if (cond) {
                navigate('/account/updates/weekly/episodes')
            } else {
                navigate('/account/updates/daily/episodes')
            }
        }
    }, []);

    const Sidebar = () => {
        const [showNav, setShowNav] = useState(!isMobile);

        return (
        <div className="account-nav">
            <div className="account-nav-header">Navigate</div>
            {(!showNav && isMobile) ?
                <div className="account-menu-btn" onClick={() => setShowNav(true)}>&#9776; &nbsp;Open Account Navigation</div>
            :
            <div className="account-mobile-dropdown">
                <NavLink to="updates/daily/episodes" className={pathname.includes('updates/daily') ? "account-tab active" :  "account-tab"}>Daily Summaries</NavLink>
                <NavLink to="updates/weekly/episodes" className={pathname.includes('updates/weekly') ? "account-tab active" :  "account-tab"}>Weekly Summaries</NavLink>
                <NavLink to="portfolio" className={({isActive}) => (isActive) ? "account-tab active" :  "account-tab"}>Portfolio</NavLink>
                <NavLink to="settings" className={({isActive}) => (isActive) ? "account-tab active" :  "account-tab"}>Settings</NavLink>
                <div className="account-tab" onClick={logout}>Logout</div>
                {(showNav && isMobile) &&
                    <div className="account-tab" onClick={() => setShowNav(false)}>&#x2715; &nbsp;Close Navigation</div>
                }
            </div>
            }
        </div>
        )
    }

    if (!user) return null;

    // IMPORTANT: Need user check or else the website won't render. Redirect is called on App.js for undefined user object, but all components
    // need to be able to render navigate('/signin') occurs. Note once a path is set, it cannot be changed; redirect can be called on the 
    // constructor but react-router-dom v6 doesn't support react.component
    return (<>
        <div className="account-content">
            <Sidebar />
            <div className="account-body">
                <Routes> 
                    <Route path="portfolio" element={<ViewPortfolio 
                        token={token} 
                        user={user ? user : null }
                        isMobile={isMobile} />} />
                    <Route path="settings" element={<ViewSettings 
                        token={token} 
                        user={user ? user : null } 
                        setToken={setToken} 
                        setUser={setUser} />} />
                    <Route path="updates/daily/*" element={<ViewUpdates 
                        token={token} 
                        user={user ? user : null }
                        frequency='daily'
                        subscriptions={user ? user.subscriptions : []} />} />
                    <Route path="updates/weekly/*" element={<ViewUpdates 
                        token={token} 
                        user={user ? user : null}
                        frequency='weekly'
                        subscriptions={user ? user.subscriptions : []} />} />
                </Routes>
            </div>
        </div>
    </>);
}

export default React.memo(Account);
        // <div className="bg-flat">
        // </div>
// <div className="portfolio-input-label">Filter your tickers</div>
                        // {/*<div className="portfolio-asset-transcript">{summ.summary}</div>*/}
            // <div className="account-section-header-note">Both weekly and daily summaries are listed here</div>

// onClick={(e) => {e.stopPropagation(); setShowSummaries(!showSummaries)}}

// <input type='text'
//     autoComplete='off'
//     className="account-filter"
//     placeholder='Filter episodes'
//     onChange={(e) => setSearchTerm(e.target.value)} />
import React, { useState, useEffect, useRef } from 'react';
import './section_episode.scss'

import DemoPlayer from './demo_player'

const SectionEpisode = () => {

    const [episodeURL, setEpisodeURL] = useState(null);
    const [showPlayer, setShowPlayer] = useState(false);

    let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=episode.get.sample`

    useEffect(async () => {
        // this only works on production atm, as there isn't an empisode template for dev
        let res = await fetch(url, { method : 'GET' });
        let data = await res.json();

        if (!data.episodes) return;
        let audio_url = `https://cadence-${process.env.REACT_APP_ENV}-audio-episodes-daily.s3.us-west-2.amazonaws.com/${data.episodes[0].audio_key}`
        setEpisodeURL(audio_url);
    }, [])

    function handlePlay() {
        setShowPlayer(true)
    }

    return (
        <div className="section-feature episode">
            <div className={showPlayer ? "tile-gradient showPlayer" : "tile-gradient"}>
                <div className="feature-descr tile">
                    <div className="feature-details tile">Listen to a sample portfolio summary here &#128071;</div>
                    <div className="feature-audio-section">
                        <DemoPlayer url={episodeURL} playCallback={handlePlay} />
                    </div>
                </div>
                <div className="demo-portfolio-container">
                    <div className="demo-portfolio-name weight-medium">Sample Portfolio</div>
                    <div className="demo-asset-container">
                        <div className="demo-asset-symbol">AAPL</div>
                        <div className="demo-asset-name">Apple</div>
                    </div>
                    <div className="demo-asset-container">
                        <div className="demo-asset-symbol">FB</div>
                        <div className="demo-asset-name">Meta</div>
                    </div>
                    <div className="demo-asset-container ">
                        <div className="demo-asset-symbol">JPM</div>
                        <div className="demo-asset-name">JP Morgan & Co.</div>
                    </div>
                    <div className="demo-asset-container">
                        <div className="demo-asset-symbol">SBUX</div>
                        <div className="demo-asset-name">Starbucks Corporation</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// { searched && 
//             <AudioPlayer 
//                 id="demo-audio"
//                 url={summaryObj.resource_url} />
//             }
export default React.memo(SectionEpisode)
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Infotip from './../components/tooltip.js'
import {useNavigate, useLocation, BrowserRouter as Router, Switch, Routes, Route, NavLink } from "react-router-dom";
import AssetSummaries from './asset_summaries'

const ViewPortfolio = ({token, isMobile}) => {
    const [focus, setFocus] = useState(false);
    const [assets, setAssets] = useState([]);
    const [showNav, setShowNav] = useState(false);

    const setSearchTerm = () => {}

    // Wrapped in on-mount, otherwise it gets called many times.
    useEffect(() => {
        if (!token) return;
        let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=portfolio.get&token=${token}`;
        // console.log(url);
        fetch(url, {method:'GET'})
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                // asset array [symbol, asset_name, id]
                if (!data || !data.assets || !data.assets[0]) return;
                // console.log(data.assets);
                setAssets(data.assets);
            })
    }, [token])
    
    if (!token) return null;

    return (
        <>
        {/*<input type='text'
            autoComplete='off'
            className="account-filter"
            placeholder='Filter portfolio by name or symbol'
            onChange={(e) => setSearchTerm(e.target.value)} />*/}
        <div className="account-section-label">
            <div className="account-section-header">Summaries by Stock</div>
            {(!showNav) ?
                <div className="account-menu-btn edit-portfolio" onClick={() => setShowNav(true)}>&#9776; &nbsp;Edit Stocks</div>
            :
            <div className="account-dropdown edit-portfolio">
                <NavLink to="/account/updates/weekly/edit" exact={true} className={({isActive}) => isActive ? "active account-tab" : "account-tab" }>Edit Weeklys
                </NavLink>
                <NavLink to="/account/updates/daily/edit" exact={true} className={({isActive}) => isActive ? "active account-tab" : "account-tab" }>Edit Dailys
                </NavLink>
                {(showNav) &&
                    <div className="account-tab" onClick={() => setShowNav(false)}>&#x2715; &nbsp;Close Navigation</div>
                }
            </div>
            }
        </div>
        <div className="portfolio-asset header">
            <span className='portfolio-asset-inforow header'>
                <div className="portfolio-asset-symbol">Symbol</div>
                <div className="portfolio-asset-name minimizable">Name</div>
                {!isMobile && <>
                    <div className="portfolio-asset-price">Day open</div>
                    <div className="portfolio-asset-price">Day close</div>
                    <div className="portfolio-asset-price">% Change</div>
                </>}
                <div className="portfolio-asset-minimize"></div>
            </span>
        </div>
        <div className="scrollable-y account-portfolio">
        {(assets.length > 0 && assets[0][0])  && assets.map((asset, index) => (
                <AssetSummaries key={index} asset={asset} token={token} isMobile={isMobile} />
            ))}
        </div>
        </>
    )
}

export default React.memo(ViewPortfolio);
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './entry.scss';
import {capitalize} from '../components/utils'
import PortfolioBox from './../components/portfolio_box'

const Cart = ({token, user, setUser}) => {
    const navigate = useNavigate();

    const [assets, setAssets] = useState([])
    const [frequency, setFrequency] = useState('')
    const [episodeType, setEpisodeType] = useState('')
    const [price, setPrice] = useState('');

    const [step, setStep] = useState(1)
    const [error, setError] = useState('')

    const [isProd, setIsProd] = useState(process.env.REACT_APP_ENV == 'production')

    const [isLoading, setIsLoading] = useState(false);
    const [showPop, setShowPop] = useState(false);

    const [config, setConfig] = useState({});

    let config_free = {
        type : 'free',
        limit : process.env.REACT_APP_FREE_LIMIT,
        content: `Free tier has a limit of ${process.env.REACT_APP_FREE_LIMIT} stocks. To add more, upgrade to a paid service. First month free, cancel anytime.`,
        buttonLabel: `Try for one month free`,
        buttonAction: function (showMessage) {
            setEpisodeType('paid');
            if (frequency == 'daily') {
                setPrice(`$${process.env.REACT_APP_PRICE_DAILY} / mo.`);
            } else {
                setPrice(`$${process.env.REACT_APP_PRICE_WEEKLY} / mo.`);
            }
            // hide message before delay to prevent showing paid message
            showMessage(false);
            // animation
            setShowPop(true);
            setTimeout(() => { setShowPop(false) }, 400);
            // prevents new message from showing before fadeout
            setTimeout(() => { setConfig(config_paid) }, 500); 
        },
    }

    let config_paid = {
        type : 'paid',
        limit : process.env.REACT_APP_PAID_LIMIT, 
        content: `Our current limit is ${process.env.REACT_APP_PAID_LIMIT} stocks, if you'd like to add more you can submit a request`,
        buttonLabel: `Request for More`,
        buttonAction: function () {
            window.location.href = "mailto:support@heycadence.com?subject=Limit%20Increase%20Request&body=Hi,%20I%20would%20like%20to%20request%20a%20limit%20increase.";
        },
    }

    useEffect(() => {
        if (!user) {
            return navigate('/signin');
        }
        try {
            let store_assets = window.localStorage.getItem('assets');

            // fequency may be empty in step 1:
            // 3 options (episode_type, episode_frequency)
            // 1. ('free', '')
            // 2. ('paid', 'daily')
            // 3. ('paid', 'weekly')
            let store_frequency = window.localStorage.getItem('signup_episode_frequency');
            let store_episode_type = window.localStorage.getItem('signup_episode_type');

            store_assets = JSON.parse(store_assets)

            setAssets(store_assets)
            setEpisodeType(store_episode_type)
            setFrequency(store_frequency)

            if (store_episode_type == 'free') {
                setConfig(config_free)
            } else  {
                setConfig(config_paid)
            }

            if (store_frequency == 'daily') {
                setPrice(`$${process.env.REACT_APP_PRICE_DAILY} / mo.`);
                setFrequency('daily');
            } else if (store_frequency == 'weekly') {
                setPrice(`$${process.env.REACT_APP_PRICE_WEEKLY} / mo.`);
                setFrequency('weekly');
            }

            // TODO: Remove this 
            // setConfig(config_paid)
        } catch {
            // console.log(('error initializing cart'));
            window.localStorage.removeItem('assets')
            window.localStorage.removeItem('frequency')
        }
    }, []);

    const pickFrequency = (freq) => {
        setFrequency(freq);
        if (freq == 'daily') {
            setPrice(`$${process.env.REACT_APP_PRICE_DAILY} / mo.`);
        } else {
            setPrice(`$${process.env.REACT_APP_PRICE_WEEKLY} / mo.`);
        }
    }

    const onNext = async (frequency, assets, tag) => {
        setIsLoading(true);

        const symbols = assets.map(a => a.s);

        const payload = {
            intent: 'template.create',
            token: token,
            new_template: {
                symbols: symbols,
                frequency: frequency,
                tag: 'test', 
                tier : episodeType,
            }
        };

        // console.log(payload);

        let res = await fetch(process.env.REACT_APP_API_ENDPOINT, {method:'POST', body: JSON.stringify(payload)});
        // console.log(res);
        let template_res = await res.json();
        // console.log(template_res);

        if (template_res.status == 'success') {
            // Remove saved assets
            window.localStorage.removeItem('assets')
            window.localStorage.removeItem('frequency')
        }

        let user_copy = {...user}; // '=' won't trigger useEffect
        user_copy.subscriptions.push(frequency);
        setUser(user_copy);

        if (episodeType == 'paid') {
            // Direct to payment gateway
            // console.log('reaching payment');
            let price_id = (frequency == 'weekly') ? process.env.REACT_APP_PROD_ID_WEEKLY : process.env.REACT_APP_PROD_ID_DAILY;
            
            // requires user be authenticated, e.g. after account creation
            let res2 = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST', 
                body: JSON.stringify({
                    intent: 'checkout.create', 
                    token: token,
                    stripe_customer_id: user.stripe_customer_id,
                    price_id : price_id,
                    template_id : template_res.template_id
                })
            });

            let data2 = await res2.json();
            // console.log(data2)
            window.location = data2.url;
        } else {
            navigate('/account/updates/'+frequency+'/episodes'); 
        }
    }

    const proceed = (step) => {
        if (frequency != 'daily' && frequency != 'weekly' && isProd) {
            setError('Please choose how often you wish to be notified');
        } else {
            setError('');
            setStep(step)
        }
    }

    if (!user) return null;

    return (
        <div className="entry-cover">
            <div className="entry-content">
                <div className="entry-logo"></div>
                <div className="entry-perks cart">
                    { (step == 1) && <>
                        <div className="entry-perk"><span className="material-icons-outlined">playlist_add</span>&nbsp; Select a timeframe</div>
                        <div className="entry-perk-descr">
                        You can add to, change, or cancel your selection in your settings at any time
                        <br></br> 
                        </div>
                    </> }
                    { (step === 2) && <>
                        <div className="entry-back" onClick={() => setStep(1)}><span className="material-icons-outlined">arrow_back</span>&nbsp; Previous step</div>
                        <div className="entry-checkout-header">You've selected</div>
                        <div className={showPop ? "pop-animation entry-checkout-container" : "entry-checkout-container"}>
                            <div className="entry-checkout-title">{episodeType == 'free' ? `Casual ${capitalize(frequency)}` : capitalize(frequency) }</div>
                            <div className="entry-checkout-price">{episodeType == 'free' ? 'Free' : price }</div>
                        </div>
                        <div className="entry-perk"><span className="material-icons-outlined">playlist_add</span>&nbsp; Add your portfolio companies</div>
                        <div className="entry-perk-descr">Use the search bar to find your companies. Drag to order your recordings
                        <br></br> 
                        <br></br> 
                        Tip: You can change your selection anytime
                        <br></br> 
                        </div>
                    </> }
                </div>
                <div className="entry-container cart">
                    {/*<div className="entry-title cart">Add your portfolio companies</div>*/}
                    {step === 1 &&
                        <>
                            <div className="cart-selection-header">How often would you like to be updated?</div>
                            <div className={ (user.subscriptions.includes("daily") && isProd)? "frequency-option-disabled" : "" }>
                                <div className={ frequency === "daily" ? 
                                    "frequency-option hoverable active" : 
                                    "frequency-option hoverable" }
                                    onClick={() => pickFrequency('daily')}>
                                    { user.subscriptions.includes("daily") 
                                        && <div className="frequency-option-descr status">Already Subscribed</div> }
                                    <div className="frequency-option-title">Daily</div>
                                    <div className="frequency-option-descr">You receive your portfolio news summary every morning from Tuesday to Saturday for events of the prior day</div>
                                </div>
                            </div>
                            <div className={ (user.subscriptions.includes("weekly") && isProd ) ? "frequency-option-disabled" : "" }>
                                <div className={ frequency === "weekly" ? 
                                    "frequency-option hoverable active" : 
                                    "frequency-option hoverable" }
                                    onClick={() => pickFrequency('weekly')}>
                                    { user.subscriptions.includes("weekly") 
                                        && <div className="frequency-option-descr status">Already Subscribed</div> }
                                    <div className="frequency-option-title">Weekly</div>
                                    <div className="frequency-option-descr">You receive your portfolio news summary every Sunday morning covering events that week</div>
                                </div>
                            </div>
                            { error != '' && <div className="entry-error">{error}</div> }
                            <div className="portfolio-next-btn cart hoverable" onClick={() => proceed(2)}>Next</div>
                        </>
                    }
                    {step === 2 && 
                        <>
                        <div className="entry-back mobile" onClick={() => setStep(1)}><span className="material-icons-outlined">arrow_back</span>&nbsp; Previous step</div>
                        <div className="cart-selection-header assets">Add your companies</div>
                        <PortfolioBox 
                            loadAssets={assets}
                            loadFrequency={frequency}
                            token={token} 
                            className="signup" 
                            onNext={onNext}
                            config={config}
                            searchPlaceholder="Search your companies here"
                            buttonLabel={isLoading ? "Loading..." : "Save" } />
                        </>
                        }
                </div>
            </div>
        </div>
    )
}

// Do not memoize this component. Changing frequency in step 1 will not update the price in step 2. 
export default React.memo(Cart);

            // <div className="bg-flat"></div>

import React, {useState } from 'react';
import './tooltip.scss';

const Infotip = ({ children, text, ...rest }) => {
  const [show, setShow] = useState(null);
  const [delayHandler, setDelayHandler] = useState(null)

  const handleMouseEnter = event => {
    setDelayHandler(setTimeout( () => {
      setShow(true)
    }, 200))
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    setShow(false)
  }

  return (
    <div className="tooltip-container">
      <div className={show ? 'tooltip-box visible' : 'tooltip-box'}>
        {text}
        <span className="tooltip-arrow" />
      </div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        <div className="info-tip">i</div>
      </div>
    </div>
  );
};

export default Infotip;



// const Tooltip = ({ children, text, ...rest }) => {
//   const [show, setShow] = useState(null);
//   const [delayHandler, setDelayHandler] = useState(null)

//   const handleMouseEnter = event => {
//     setDelayHandler(setTimeout( () => {
//       setShow(true)
//     }, 500))
//   }

//   const handleMouseLeave = () => {
//     clearTimeout(delayHandler)
//     setShow(false)
//   }

//   return (
//     <div className="tooltip-container">
//       <div className={show ? 'tooltip-box visible' : 'tooltip-box'}>
//         {text}
//         <span className="tooltip-arrow" />
//       </div>
//       <div
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         {...rest}
//       >
//         {children}
//       </div>
//     </div>
//   );
// };
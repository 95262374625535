import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import './entry.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Login / signup.
const NotFound = ({setToken, setUser}) => {
    const navigate = useNavigate();
    let query = useQuery();
    const [status, setStatus] = useState('Oops')
    const [message, setMessage] = useState('It appears you have accidentally stumbled into an unused page.')
    const [buttonName, setButtonName] = useState('Go to Account');

    useEffect(() => {
        // let status = query.get("status");
        // console.log(status)
        // setStatus('Oops')
        // setMessage("It appears you've accidentally stumbled into an unused page.")
    }, [])

    return (
    <div className="entry-cover">
        <div className="entry-content">
            <div className="entry-container">
                <div className="entry-title">{status}</div>
                <div className="entry-msg">{message}</div>
                <Link to="/" className="entry-btn">Go Home</Link>
            </div>
        </div>
    </div>
    )
}

export default React.memo(NotFound);

//         <div className="bg-dot">
        //     <div className="bg"></div>
        // </div>
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import './entry.scss';
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = ({setToken, setUser}) => {
    let navigate = useNavigate();
    let query = useQuery();
    const [status, setStatus] = useState('reset'); // reset / loading / done, where reset is default
    const [error, setError] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');

    const [message, setMessage] = useState('')
    const [buttonName, setButtonName] = useState('Go to Account');

    useEffect(() => {
        let token = query.get("token");
        if (!token || token.length < 50) {
            navigate('/not_found');
        }
    }, [])

    const handleUpdatePass = () => {
        if (password.length < 8) return setError('Please provide a password of at least 8 characters');
        if (password !== confirmPass) return setError('Please verify that your passwords match')

        let payload = {
            intent: 'password.reset',
            token : query.get("token"), 
            new_password : password
        }

        setStatus('loading');

        fetch(api_endpoint, {method:'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.status == 'success') {
                setStatus('done')
            } else {
                setError('Unable to update password: Secure link is invalid');
                setStatus('reset')
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
    <div className="entry-cover">
        <div className="entry-content">
            
            {status == 'done' ?
            <div className="entry-container">
                <div className="entry-title">Password Reset Complete</div>
                <div className="entry-msg">Your password has been reset, you can now log in using your new password.</div>
                <Link to="/signin" className="entry-btn">Sign In</Link>
            </div>
            :
            <div className="entry-container">
                <div className="entry-title">Secure Password Reset</div>
                <div className="entry-msg">Use the inputs below to enter your new password. Once you are ready, press "Update Password"</div>
                <div className="entry-error signin">{error}</div>
                <div className="entry-item">
                    <div className="entry-label">New Password</div>
                    <input type="password" 
                        className="entry-field" 
                        onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div className="entry-item">
                    <div className="entry-label">Confirm New Password</div>
                    <input type="password" 
                        className="entry-field" 
                        onChange={(e) => setConfirmPass(e.target.value)}/>
                </div>
                <div className="entry-btn" 
                    onClick={handleUpdatePass}>{(status == 'loading') ? 'Updating...' : 'Update Password'}</div>
            </div>
            }
        </div>

    </div>
    )
}

export default React.memo(ResetPassword);

        // <div className="bg-dot">
        //     <div className="bg"></div>
        // </div>
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Element } from 'react-scroll'

import './pricing.scss'

const SectionPricing = ({token, scrollRef}) => {
    let navigate = useNavigate();

    // useEffect(() => {
    //     console.log(token);
    // }, [])

    function signup(frequency, episode_type) {
        // 3 options (episode_type, episode_frequency)
        // 1. ('free', '')
        // 2. ('paid', 'daily')
        // 3. ('paid', 'weekly')
        try {
            window.localStorage.setItem('signup_episode_frequency', frequency);
            // either free or paid
            window.localStorage.setItem('signup_episode_type', episode_type);
        } catch (e) {
            window.localStorage.removeItem('signup_episode_type');
            window.localStorage.removeItem('signup_episode_frequency');
        } 
        if (!token) {
            navigate('/signup');
        } else {
            navigate('/cart')
        }
    }

    return (
        <Element name="pricing" className="section-feature centered pricing frosted">
            <div className="feature-descr centered pricing">
                <div className="feature-header pricing">Get Started in Minutes</div>
                <div className="feature-details">Less than $0.20 / day. Cancel anytime.</div>
            </div>

{/*            <div className="feature-beta">
                <div className="feature-beta-title">Beta Availability</div>
                <div className="feature-beta-descr">Spots for new users are limited in beta and offered on a first come, first served basis. If all spots are filled, we may open up more.</div>
                <div className="feature-beta-bar">
                    <div className="feature-beta-progress"></div>
                </div>
                <div className="feature-beta-descr">Current Status: <span className="feature-beta-status active">Invite Only</span></div>
                <a href="mailto:invites@heycadence.com?subject=Hey Cadence Invite Request&body=Hi! %0D%0A%0D%0AI would like to request for an invite." className="feature-beta-btn">Request for Invite</a>
            </div>*/}

            <div className="section-subscription">
                <div className="subscription-package">
                    <div className="subscription-name">Casual</div>
                    <div>
                        <span className="subscription-price">Free</span>
                    </div>
                    <div className="subscription-details">Great for trying out</div>
                    <div className="subscription-feature-list">
                        <div className="subscription-feature">No payment info required</div>
                        <div className="subscription-feature">Get either daily or weekly summaries</div>
                        <div className="subscription-feature">Up to {process.env.REACT_APP_FREE_LIMIT} stocks</div>
                        <div className="subscription-feature">AI powered summarization</div>
                        <div className="subscription-feature">Neural audio narration</div>
                        <div className="subscription-feature">Downloadable mp3 for offline listening</div>
                    </div>
                    <div className="subscription-btn weight-medium" onClick={() => signup('', 'free') }>Get Started <span className="material-icons-outlined">chevron_right</span></div>
                    <div className="subscription-contact">Have questions? We'd be happy to address them <a href="mailto:sales@heycadence.com" className="subscription-contact-btn">Contact Sales</a></div>
                </div>

                <div className="subscription-package">
                    <div className="subscription-flag">1 Month Free</div>
                    <div className="subscription-name">Daily</div>
                    <div>
                        <span className="subscription-price">{`$${process.env.REACT_APP_PRICE_DAILY}`}</span>
                        <span className="subscription-basis"> / month</span>
                    </div>
                    <div className="subscription-details">Great for keeping a close eye on the market</div>
                    <div className="subscription-feature-list">
                        <div className="subscription-feature">Covers news from Monday to Friday</div>
                        <div className="subscription-feature">Free of ads</div>
                        <div className="subscription-feature">AI powered summarization</div>
                        <div className="subscription-feature">Neural audio narration</div>
                        <div className="subscription-feature">Downloadable mp3 for offline listening</div>
                        <div className="subscription-feature">Cancel anytime</div>
                    </div>
                    <div className="subscription-btn weight-medium" onClick={() => signup('daily', 'paid') }>Get Started<span className="material-icons-outlined">chevron_right</span></div>
                    <div className="subscription-contact">Have questions? We'd be happy to address them <a href="mailto:sales@heycadence.com" className="subscription-contact-btn">Contact Sales</a></div>
                </div>

                <div className="subscription-package">
                    <div className="subscription-flag">1 Month Free</div>
                    <div className="subscription-name">Weekly</div>
                    <div>
                        <span className="subscription-price">{`$${process.env.REACT_APP_PRICE_WEEKLY}`}</span>
                        <span className="subscription-basis"> / month</span>
                    </div>
                    <div className="subscription-details">Great for infrequent news highlights</div>
                    <div className="subscription-feature-list">
                        <div className="subscription-feature">Weekly round up every Sunday</div>
                        <div className="subscription-feature">Free of ads</div>
                        <div className="subscription-feature">AI powered summarization</div>
                        <div className="subscription-feature">Neural audio narration</div>
                        <div className="subscription-feature">Downloadable mp3 for offline listening</div>
                        <div className="subscription-feature">Cancel anytime</div>
                    </div>
                    <div className="subscription-btn weight-medium" onClick={() => signup('weekly', 'paid') }>Get Started <span className="material-icons-outlined">chevron_right</span></div>
                    <div className="subscription-contact">Have questions? We'd be happy to address them <a href="mailto:sales@heycadence.com" className="subscription-contact-btn">Contact Sales</a></div>
                </div>

            </div>
        </Element>
    )
}

export default React.memo(SectionPricing);
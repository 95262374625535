import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './entry.scss';
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;


function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function randInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const sendMsgs = ['Dispatching carrier pigeons...', 'Sending message in a bottle...']

const SignUp = ({setToken, setUser, isMobile}) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [referralCode, setReferralCode] = useState('');

    const [episodeType, setEpisodeType] = useState('free');
    const [frequency, setFrequency] = useState('daily');
    const [price, setPrice] = useState('');

    const [showReferral, setShowReferral] = useState(false)
    const [tos, setTos] = useState(false)
    const [buttonName, setButtonName] = useState('Create account')
    const [error, setError] = useState('');

    useEffect(() => {
        try {
            // 3 options (episode_type, episode_frequency)
            // 1. ('free', '')
            // 2. ('paid', 'daily')
            // 3. ('paid', 'weekly')

            let store_episode_type = window.localStorage.getItem('signup_episode_type');
            let store_frequency = window.localStorage.getItem('signup_episode_frequency');
            console.log(store_episode_type, store_frequency);

            if (store_frequency == 'daily') {
                setPrice(`$${process.env.REACT_APP_PRICE_DAILY} / mo.`);
                setEpisodeType('paid');
                setFrequency('daily');
            } else if (store_frequency == 'weekly') {
                setPrice(`$${process.env.REACT_APP_PRICE_WEEKLY} / mo.`);
                setEpisodeType('paid');
                setFrequency('weekly');
            } 

            if (store_episode_type == '') {
                // Frequency to be determined on cart page
                setEpisodeType('free');
                setPrice('Free');
            }
        } catch {
            window.localStorage.removeItem('signup_episode_type');
            window.localStorage.removeItem('signup_episode_frequency');
        }
    }, [])

    const submit = () => {
        if (!validateEmail(email)) return setError('Please provide a valid email')
        if (password1.length < 8) return setError('Please provide a password of at least 8 characters');
        if (password1 != password2) return setError('Passwords do not match');
        if (!tos) return setError('You have not agreed to our Terms of Use');

        const payload = {
            intent: 'user.register', 
            user_type: 'basic',
            email: email, 
            password: password1, 
            referral_code: referralCode,
        }

        setButtonName(sendMsgs[randInt(0, sendMsgs.length)])
        fetch(api_endpoint, {method:'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.status === 'success') {
                setButtonName('Done!');

                setToken(data.token);
                setUser(data.user);
                // window.localStorage.setItem('token', data.token);
                // window.localStorage.setItem('user', JSON.stringify(data.user));
                navigate("/cart");
                return;
            } else {
                setButtonName('Create account')
                // TODO: Add error handling for email already exists
                setError('Error: Account could not be created');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
    <div className="entry-cover">
        <div className="entry-content">
            <div className="entry-logo"></div>
            <div className="entry-perks signup">
                <div className="entry-checkout-header">You are signing up for</div>
                <div className="entry-checkout-container">
                    <div className="entry-checkout-title">{episodeType == 'free' ? 'Casual' : capitalize(frequency) }</div>
                    <div className="entry-checkout-price">{episodeType == 'free' ? 'Free' : price }</div>
                </div>
            </div>
            <div className="entry-container signup">
                <div className="entry-title signup">Create an account</div>
                {/*<div className="entry-msg onboarding">Sign up is currently by invite only. Be sure to enter your referral code below</div>*/}
                {isMobile && <>
                    <div className="entry-checkout-header">You are signing up for</div>
                    <div className="entry-checkout-container">
                        <div className="entry-checkout-title">{episodeType == 'free' ? 'Casual' : capitalize(frequency) }</div>
                        <div className="entry-checkout-price">{episodeType == 'free' ? 'Free' : price }</div>
                    </div>
                </>}
                <div className="entry-item">
                    <div className="entry-label mobile-hide">Email</div>
                    <input type="text" 
                            placeholder="Email@example.com"
                            onChange={e => setEmail(e.target.value)}
                            className="entry-field" />
                </div>
                <div className="entry-item">
                    <div className="entry-label mobile-hide">Password</div>
                    <input 
                            type="password" 
                            placeholder={isMobile ? "Password" : ""}
                            onChange={e => setPassword1(e.target.value)}
                            className="entry-field" />
                </div>
                <div className="entry-item">
                    <div className="entry-label mobile-hide">Confirm Password</div>
                    <input type="password"
                        placeholder={isMobile ? "Confirm Password" : ""}
                        onChange={e => setPassword2(e.target.value)}
                        className="entry-field" />
                </div>
                    {/*{!showReferral ? 
                    <div className="entry-link referral" onClick={() => setShowReferral(true)}>
                        Add Discount Code
                    </div>
                    : <input className="entry-field referral" 
                        type="text" 
                        onChange={e => setReferralCode(e.target.value) }
                        placeholder="Enter referral code" />
                    }*/}
                <div className="entry-info checkbox">
                    <input type="checkbox" 
                        className="entry-checkbox"
                        checked={tos}
                        onChange={() => setTos(!tos)}/> I agree to the <Link to="/tos" className="link">Terms of Service</Link> and <Link to="/privacy" className="link">Privacy Notice</Link>
                </div>
                <div className="entry-error">{error}</div>
                <div className="entry-btn signup"
                    onClick={() => submit()}>{buttonName}&nbsp; <span className="material-icons-outlined">arrow_forward</span></div>
                <div className="entry-change">Already have an account? &nbsp;<Link to="/signin" className="link">Sign in</Link></div>
            </div>
        </div>
    </div>
    )
}

export default React.memo(SignUp);
        // <div className="bg-flat"></div>

                // <div className="entry-perk"><span className="material-icons-outlined">view_in_ar</span>&nbsp; Tailored Summaries</div>
                // <div className="entry-perk-descr multipoint">Hear stock news of your portfolio</div>
                // <div className="entry-perk"><span className="material-icons-outlined">auto_awesome</span>&nbsp; Set & Forget</div>
                // <div className="entry-perk-descr multipoint">Set it once and we'll do the rest</div>
                // <div className="entry-perk"><span className="material-icons-outlined">flight_takeoff</span>&nbsp; News On the Go</div>
                // <div className="entry-perk-descr multipoint">Download it as Mp3 or listen online</div>
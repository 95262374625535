import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import './entry.scss';
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EmailConfirmation = () => {
    let navigate = useNavigate();
    let query = useQuery();
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('')
    const [buttonName, setButtonName] = useState('Go to Account');

    useEffect(() => {
        let status = query.get("status");
        // console.log(status)

        if (status === 'success') {
            setStatus('🥳 Email Confirmed')
            setMessage("Your email has been verified! We're excited to have you as part of our community.")
        } else if (status === 'failed') {
            setStatus("Unable to Confirm Email")
            setMessage("We attempted to verify your email, but we could not.")
        } else {
            navigate('/not_found');
        }
    }, [])

    return (
    <div className="entry-cover">
        <div className="entry-content">
            <div className="entry-container">
                <div className="entry-title">{status}</div>
                <div className="entry-msg">{message}</div>
                <div className="entry-btn"
                    onClick={() => navigate('/account')}>{buttonName}</div>
            </div>
        </div>
    </div>
    )
}

export default React.memo(EmailConfirmation);
        // <div className="bg-dot">
        //     <div className="bg"></div>
        // </div>
import React from 'react'
import Marquee from "react-fast-marquee";

import logo_cnbc from './../media/cnbc.png'
import logo_techcrunch from './../media/techcrunch.png'
import logo_businessinsider from './../media/businessinsider.png'
import logo_cnn from './../media/cnn.png'
import logo_bloomberg from './../media/bloomberg.png'
import logo_reuters from './../media/reuters.png'

const SectionSources = () => {
    return (
        <div className="section-feature centered">
            <div className="sources-bg feature-content">
                <div className="feature-details credibility text">Your news is important. We source only from credible outlets to ensure the highest accuracy and reliability. </div>
                <div className="sources-container">
                <Marquee speed={30} gradientColor={[45, 57, 77]} gradientWidth={50}>
                  <div className="source-logo" id="bloomberg" 
                    style={{backgroundImage : "url(" + logo_bloomberg + ")" }}></div>
                  <div className="source-logo" id="cnn" 
                    style={{backgroundImage : "url(" + logo_cnn + ")" }}></div>
                  <div className="source-logo" id="reuters" 
                    style={{backgroundImage : "url(" + logo_reuters + ")" }}></div>
                  <div className="source-logo" id="cnbc" 
                    style={{backgroundImage : "url(" + logo_cnbc + ")" }}></div>
                  <div className="source-logo" id="businessinsider" 
                    style={{backgroundImage : "url(" + logo_businessinsider + ")" }}></div>
                  <div className="source-logo" id="techcrunch" 
                    style={{backgroundImage : "url(" + logo_techcrunch + ")" }}></div>
                </Marquee>
                </div>
        </div>
      </div>
    )
}

// <div className="feature-header centered">Trustworthy</div>
export default React.memo(SectionSources)
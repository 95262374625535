import React, { useState, useEffect, useRef, useMemo } from 'react';
import Infotip from './../components/tooltip.js'
import {useNavigate, useLocation, BrowserRouter as Router, Switch, Routes, Route, NavLink } from "react-router-dom";

import PlayableItem from './../components/playable_item'

// used for summaries and episode
const AssetSummaries = ({asset, index, token, isMobile}) => {
    const [summaries, setSummaries] = useState([])
    const [showSummaries, setShowSummaries] = useState(false)
    const audioRef = useRef(null)

    function getSummary() {
        setShowSummaries(!showSummaries)
        if (summaries.length > 0) return;
        let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=summary.get.auth&limit=5&symbol_id=${asset[2]}&token=${token}`;
        
        fetch(url, {method:'GET'})
        .then(res => res.json())
        .then(data => {
            // console.log(data)
            if (!data || !data.summaries || !data.summaries[0]) return;
            let res = data.summaries;
            // news_period is given time-zone agnostic
            let options = { timeZone: 'UTC', weekday: "long", year: "numeric", month: "short",  day: "numeric"};

            for (let i = 0; i < data.summaries.length; i++) {
                let date = new Date(data.summaries[i].news_period);
                res[i].display_date = date.toLocaleDateString("en-us", options);
                res[i].loadToPlayer = false;
                res[i].audio_url = `https://cadence-${process.env.REACT_APP_ENV}-audio-summaries-${res[i].frequency}.s3.us-west-2.amazonaws.com/${res[i].audio_key}`;
            };

            setSummaries(res)
        })
    }

    return (
        <>
        {(asset && asset[0]) && 
            <div className={showSummaries ? "portfolio-asset wrap hoverable" : "portfolio-asset hoverable"} key={asset[0]} onClick={getSummary}>
                <span className={showSummaries ? 'portfolio-assets-headerfy portfolio-asset-inforow' : ' portfolio-asset-inforow'}>
                    <div className="portfolio-asset-symbol">{asset[0].split('-')[1]}</div>
                    <div className="portfolio-asset-name minimizable">{asset[1]}</div>
                    {!isMobile && <>
                        <div className="portfolio-asset-price">{asset[3] ? asset[3] : ''}</div>
                        <div className="portfolio-asset-price">{asset[4] ? asset[4] : ''}</div>
                        <div className={asset[4] >= asset[3] ? "portfolio-asset-price gain" : "portfolio-asset-price loss"}>
                        { asset[3] ?
                            Number((asset[4] - asset[3]) / asset[3] * 100).toFixed(1) + '%'
                            :
                            ''
                        }
                        </div>
                    </>}
                    <div className="portfolio-asset-minimize">
                        {showSummaries ? 'Minimize' : 'Expand'}
                    </div>
                </span>
                
                {showSummaries && <>
                    <div className="portfolio-asset-meta desktop-hide">
                        <div className="portfolio-asset-meta-stat">
                            <div className="portfolio-asset-info">Day open</div>
                            <div className="portfolio-asset-meta-price">{asset[3] ? asset[3] : ''}</div>
                        </div>
                        <div className="portfolio-asset-meta-stat">
                            <div className="portfolio-asset-info">Day close</div>
                            <div className="portfolio-asset-meta-price">{asset[4] ? asset[4] : ''}</div>
                        </div>
                        <div className="portfolio-asset-meta-stat">
                            <div className="portfolio-asset-info">% Change</div>
                            <div className={asset[4] >= asset[3] ? "portfolio-asset-meta-price gain" : "portfolio-asset-meta-price loss"}>
                                { asset[3] ?
                                    Number((asset[4] - asset[3]) / asset[3] * 100).toFixed(1) + '%' : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flexbreak"></div>
                    {(summaries.length > 0) && summaries.map((summary, index) => (
                        <PlayableItem key={index} item={summary} />
                    ))}
                    {(summaries.length === 0 && showSummaries) &&
                        <div className="portfolio-asset-msg">There doesn't appear to be any summaries for this asset at the moment</div>
                    }
                </>
                }
            </div>
        }
        </>
    )
}

export default React.memo(AssetSummaries);
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import './entry.scss';
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Login / signup.
const PaymentsCallback = ({setToken, setUser}) => {
    let navigate = useNavigate();
    let query = useQuery();
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('')
    const [buttonName, setButtonName] = useState('Go to Account');

    useEffect(() => {
        let status = query.get("status");
        // console.log(status)

        if (status === 'success') {
            setStatus('Payment Succeeded')
            setMessage("Great you're all set! We're now tracking your portfolio for news events. You'll an email once your summary is ready.")
        } else if (status === 'failed') {
            setStatus("Unable to process payment")
            setMessage("We attempted to charge for you for your subscription, but it appears that it didn't go through. We are unable to track your assets for news events until a payment is made.")
        } else {
            navigate('/not_found');
        }
    }, [])

    const submit = () => {
        navigate('/account');

        // fetch(api_endpoint, {method:'POST', body: JSON.stringify(payload)})
        // .then(res => res.json())
        // .then(data => {
        //     console.log(data);
        //     if (data.status == 'success') {
        //         navigate('/account'); 
        //     } else {
        //         setButtonName('Sign In')
        //         if (data.details == 'login_failed')
        //             setError('An error occurred: Invalid email or password');
        //         else 
        //             setError('An unknown error occurred');
        //     }
        // })
        // .catch((error) => {
        //     console.error('Error:', error);
        // });
    }

    return (
    <div className="entry-cover">
        <div className="entry-content">
            <div className="entry-container">
                <div className="entry-title">{status}</div>
                <div className="entry-msg">{message}</div>
                <div className="entry-btn"
                    onClick={() => submit()}>{buttonName}</div>
            </div>
        </div>
        <div className="bg-dot">
            <div className="bg"></div>
        </div>
    </div>
    )
}

export default React.memo(PaymentsCallback);
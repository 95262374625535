import React, { useState, useEffect, useRef } from 'react';
import {BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Element} from 'react-scroll';

import './feature.scss';
import './home.scss';

import Hero from './hero.js'
import SectionSources from './section_sources.js'
import SectionEpisode from './section_episode.js'
import SectionDemoPortfolio from './section_demo_portfolio.js'
import SectionDemoSummary from './section_demo_summary.js'
import SectionAbout from './section_about.js'
import SectionPricing from './pricing.js'

const Home = ({token, refHero, refFeatures, refPricing, refAbout}) => {
    return (
        <>
        <Hero scrollRef={refHero} />
        <SectionEpisode />
        <div className="content-container">
            <Element name="features">
                <SectionDemoSummary scrollRef={refFeatures} />
                <SectionSources />
                <SectionDemoPortfolio />
            </Element>
            {/* About section also contains pricing */}
            <SectionPricing token={token} scrollRef={refPricing} />
            <SectionAbout scrollRef={refAbout}/>
        </div>
        </>
    )
}


export default React.memo(Home);

// <div className="bg-dot">
//     <div className="bg"></div>
// </div>
import React, {useState, useEffect } from 'react';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

import './message.scss'
import './portfolio.scss'
import Search from './search.js'
import Infotip from './tooltip.js'

import {capitalize} from './utils.js'

// or ES6+ syntax with cool fat arrows
function containsObject(obj, list) {
    return list.some(elem => elem.s === obj.s)
}

// used in accounts [onNext -> updateTemplate], signup step [onNext -> createTemplate], 
// ability to load assets, takes in array of string
const PortfolioBox = ({
    onNext, 
    className, 
    loadAssets, 
    loadFrequency, 
    loadTemplateId,
    buttonLabel, 
    isDemo,
    config,
    searchPlaceholder, 
}) => {
    const [assets, setAssets] = useState([]);
    const [frequency, setFrequency] = useState(loadFrequency);
    const [templateId, setTemplateId] = useState('');
    const [tag, setTag] = useState('');

    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => { // must be inside mount, otherwise react gets into a rerendering loop
        if (loadAssets) {
            if (!loadAssets[0] || !loadAssets[0].s || !loadAssets[0].n) return;
            setAssets(loadAssets);
        };
        if (loadFrequency) {
            setFrequency(loadFrequency)
        }
    }, [])

    useEffect(() => { // updates if parent assets change
        if (loadTemplateId) {
            setTemplateId(loadTemplateId)
        }
        if (loadFrequency) {
            setFrequency(loadFrequency)
        }
        if (loadAssets) { // put this last, it's got a return statement.
            if (!loadAssets[0] || !loadAssets[0].s || !loadAssets[0].n) return;
            setAssets(loadAssets);
        };
    }, [loadAssets, loadFrequency, loadTemplateId])
    
    const handleSubmit = (item) => {
        if (containsObject(item, assets)) return;

        if (assets.length >= config.limit) {
            setShowMessage(true);

            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            return;
        }
        setAssets([item, ...assets]) // must be a copy
    }

    const DragHandle = sortableHandle(({value}) => <span className="portfolio-asset-info">
        <div className="portfolio-asset-symbol">{value.s.split('-')[1]}</div>
        <div className="portfolio-asset-name">{value.n}</div>
    </span>);

    // idx must be passed independently, as index is used by parent 
    // and not passed.
    const SortableItem = sortableElement(({idx, value, onRemove}) => 
        <li className="portfolio-asset demo grabbable">
            <DragHandle value={value}/>
            <div className="portfolio-asset-remove" onClick={() => onRemove(idx)}>&#x2715;</div>
        </li>
    );

    const SortableContainer = sortableContainer(({children}) => {
        return <ul>{children}</ul>;
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        setAssets(arrayMoveImmutable(assets, oldIndex, newIndex))
    };

    const removeItem = (index) => {
        // must do copy, equal sign does not update
        let new_assets = [...assets]; 
        new_assets.splice(index, 1);
        assets.splice(index, 1);
        setAssets(new_assets);
    }

    const handleNext = () => {
        // if demo, then show message regardless of limit
        // limit (30) is imposed automatically when user tries to add greater than limit
        // onNext is called regardless
        if (isDemo) {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 4000);
            return onNext(frequency, assets, tag);
        } else {
            // if not demo, onNext is only called if user does not exceed the limit
            if (assets.length >= config.limit) {
                setShowMessage(true);

                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
                return;
            }
            return onNext(frequency, assets, tag);
        }
    };

    const handleMessageBtn = () => {
        if (loadTemplateId) {
            config.buttonAction(setShowMessage, templateId);
        } else {
            config.buttonAction(setShowMessage);
        }
    }

    // const executeScroll = () => scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})

    return (
    <div className={className + " portfolio-box"}>
        <div className="portfolio-item search">
            {/*<div className="portfolio-input-label search">Add stocks in your portfolio</div>*/}
            <Search onSubmit={handleSubmit} searchPlaceholder={searchPlaceholder} clearOnSubmit={true} classNames="portfolio-searchbar"/>
        </div>
        <div className="portfolio-item assets">
            <div className="portfolio-section-label">
                <div className="portfolio-input-label">Portfolio assets</div>
            </div>
            
            <div className="portfolio-assets">
                {assets.length === 0 && 
                    <div className="portfolio-msg"> &uarr; Use the search above to add your holdings</div>
                }
                <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis='y'>
                    {assets.map((asset, index) => (
                        <SortableItem key={'item-'+ asset.s} index={index} idx={index} value={asset} onRemove={removeItem}/>
                    ))}
                </SortableContainer>
            </div>
        </div>
        <div className="portfolio-action-panel">
            <div className="portfolio-next-btn hoverable" onClick={handleNext}>{buttonLabel}</div>
            { (config.type == 'free') &&
                <div className="portfolio-upgrade-btn hoverable" onClick={handleMessageBtn}>Upgrade for ${(frequency == 'daily') ? '4.95/mo.' : '1.95/mo.' } <Infotip text="Track more than 4 stocks. First Month Free" /></div>
            }
        </div>

        {(config || isDemo) &&
            <div className={showMessage ? "msg-box-container show" : "msg-box-container"}>
                <div className="msg-box-content">{ config.content }</div>
                {config.buttonLabel && 
                    <div className="msg-box-btn main" onClick={ handleMessageBtn }>{ config.buttonLabel }</div>
                }
                {isDemo ?
                    <div className="msg-box-btn dismiss" onClick={ handleMessageBtn }>Dismiss</div>
                    :
                    <div className="msg-box-btn dismiss" onClick={() => {setShowMessage(false)}}>Dismiss</div>
                }
            </div>
        }
    </div>
    )
}
export default React.memo(PortfolioBox);

        // <div className="portfolio-save-status">&#10004; Saved</div>


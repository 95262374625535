import React, { useState, useEffect, useRef } from 'react';
import {useLocation, BrowserRouter as Router, Switch, Routes, Route, NavLink, Link } from "react-router-dom";
import Infotip from './../components/tooltip.js'

import { capitalize } from './../components/utils.js'

import ViewEpisodes from './account_episodes';
import ViewEdit from './account_edit';

const ViewUpdates = ({token, user, frequency, subscriptions}) => {
    // const [showNav, setShowNav] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (!user) return;
        // console.log(subscriptions)
        // console.log(frequency)
    },[])

    const handleCartLink = () => {
        try {
            window.localStorage.setItem('signup_episode_type', 'free');
            window.localStorage.setItem('signup_episode_frequency', frequency);
        } catch (err) {
            console.error('Unable to set localStorage values for cart');
            window.localStorage.removeItem('signup_episode_type');
            window.localStorage.removeItem('signup_episode_frequency');
        }
    }

    if (!user) return null;

    return (<>
        <div className="account-section-label">
            <div className="account-section-header">{capitalize(frequency)} Summaries</div>
            { (pathname == '/account/updates/daily/edit' || pathname == '/account/updates/weekly/edit') ?
            <NavLink 
                to="episodes" 
                exact={true} 
                className='account-menu-btn edit-portfolio'>
                Show summaries
                <Infotip text="Latest summaries of your portfolio companies" />
            </NavLink>
            :
            <NavLink 
                to="edit" 
                exact={true} 
                className='account-menu-btn edit-portfolio'>
                Edit {frequency} portfolio
                <Infotip text="Edit the companies you are subscribed to and the playlist order" />
            </NavLink>
            }
            
            
        </div>
        
        {(subscriptions && subscriptions.includes(frequency)) ?
            <Routes>
                <Route path="episodes" exactly element={<ViewEpisodes 
                    token={token} 
                    frequency={frequency} 
                    user={user}
                    subscriptions={subscriptions} />} />
                <Route path="edit" element={<ViewEdit 
                    token={token} 
                    user={user}
                    frequency={frequency} />} />
            </Routes>
        :
            <div className="account-get-started">
                <div className="account-start-msg">It doesn't look like you've registered for {frequency} updates.</div>
                <Link to='/cart' onClick={handleCartLink}>
                    <div className="account-start-btn">Add {frequency} summaries</div>
                </Link>
            </div>
        }
    </>)
}

export default React.memo(ViewUpdates);
// <NavLink to="/account/updates/daily/edit" exact={true} className={({isActive}) => isActive ? "active account-tab" : "account-tab" }>Edit Weeklys
//                 </NavLink>
//                 <NavLink to="/account/updates/daily/edit" exact={true} className={({isActive}) => isActive ? "active account-tab" : "account-tab" }>Edit Dailys
//                 </NavLink>
    // let activeView;
    // useEffect(() => {
    //     if (subview === 'edit') {
            
    //     } else {
            
    //     }
    // }, [subview, frequency])

        // {/*{activeView}*/}

    // if (subscriptions && subscriptions.includes(frequency)) {
    //     if (subview === 'episodes') {
            
    //     } else {

    //         activeView = 
    //     }
    // } else {
    //     activeView = 
    // }

    // const setSearchTerm = () => {}

import React, { useState, useEffect, useRef } from 'react';

const DemoPlayer = ({url, playCallback}) => {
    const [loadPlayer, setLoadPlayer] = useState(false);
    const [playing, setPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        // console.log(url)
    }, [])

    useEffect(() => {
        // console.log(url)
        setLoadPlayer(false)
        setPlaying(false)
    }, [url])

    useEffect(() => {
        if (loadPlayer === false) return;

        if (audioRef !== null && typeof audioRef.current !== 'undefined' && url !== '') {
            audioRef.current.load();
            // console.log('loaded')
            audioRef.current.play();
            setPlaying(true);
        }
    }, [loadPlayer])

    function handlePlay(e) {
        if (!loadPlayer) {
            setLoadPlayer(true);
        }
        else if (playing) {
            audioRef.current.pause()
            setPlaying(false)
        } else if (!playing) {
            audioRef.current.play()
            setPlaying(true)
        }
        if (playCallback) {
            playCallback()
        }
    }

    return (<>
        <div className="audio-playpause" onClick={e => handlePlay(e)}>&#128266; {playing ? "Pause" : "Play"}</div>
        {loadPlayer &&
            <div className="audio-controls-drawer">
                <audio ref={audioRef} autoPlay controls>
                    <source src={url} type="audio/mpeg" />
                </audio>
            </div>
        }
    </>)
}

export default DemoPlayer;
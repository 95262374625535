import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './section_summary.scss'

import DemoPlayer from './demo_player'
import AudioPlayer from './../components/audioplayer';
import Search from './../components/search.js'

// Src: https://codepen.io/pen/
// Delay src: https://stackoverflow.com/questions/50466734/delay-react-onmouseover-event

const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
const env = process.env.REACT_APP_ENV

const SectionDemoSummary = ({scrollRef}) => {
    const [searched, setSearched] = useState(false);
    const [summaryObj, setSummaryObj] = useState({});
    const [showPop, setShowPop] = useState(false);

    let demo_assets = []

    if (env == 'production') {
        demo_assets = [
            {item: {id: 15, s: 'US-AAPL', n: 'Apple'}}, 
            {item: {id: 6088, s: 'US-TSLA', n: 'Tesla'}}, 
            {item: {id: 2207, s: 'US-FB', n: 'Facebook'}}, 
            {item: {id: 3363, s: 'US-JPM', n: 'JP Morgan Chase & Co.'}}]
    } else {
        demo_assets = [
            {item: {id: 13, s: 'US-AAPL', n: 'Apple'}}, 
            {item: {id: 6177, s: 'US-TSLA', n: 'Tesla'}}, 
            {item: {id: 2215, s: 'US-FB', n: 'Facebook'}}, 
            {item: {id: 3407, s: 'US-JPM', n: 'JP Morgan Chase & Co.'}}]
    }

    // Must use defaultValue, otherwise re-rendering causes lag in seeking
    useEffect(() => {
        // setTimeout(() => { 
            // console.log(demo_assets)
            onSubmit(demo_assets[0].item)
        // }, 50)
    },[])

    const onSubmit = (item) => {
        if (typeof(item) == 'undefined') return;

        // Search's blurOnSubmit does not run if readOnly=true
        document.activeElement.blur()
        
        let symbol_id = item.id;

        let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=summary.get&symbol_id=${symbol_id}&frequency=daily&limit=1`
        
        // console.log(url)
        fetch(url, {method : "GET"})
            .then(res => res.json())
            .then(obj => {
                // console.log(obj)
                obj.summaries[0].url = `https://cadence-${process.env.REACT_APP_ENV}-audio-summaries-daily.s3.us-west-2.amazonaws.com/${obj.summaries[0].audio_key}`
                setSummaryObj(obj.summaries[0]);
                setSearched(true);
                setShowPop(true);
                setTimeout(() => { setShowPop(false) }, 400);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div className="section-feature summary" ref={scrollRef} >
            <div className="feature-descr summary">
                <div className="feature-header">... or listen separately</div>
                <div className="feature-details">Hear the news highlights of individual companies in less than 60 seconds</div>
            <div className="feature-try"><span className="material-icons-outlined fill-arrow">south</span>&nbsp; <span>Try it on a few samples!</span></div>
                <Search classNames="home-demo" 
                    searchPlaceholder="Pick a stock from here"
                    onSubmit={onSubmit} 
                    options={demo_assets}
                    readOnly={true} />
            </div>
            <div className="feature-demo">
                { searched && 
                    <div className={ showPop ? "pop-animation feature-summary frosted" : "feature-summary frosted"} >
                        <div className="feature-summary-arrow"></div>
                            <div className="feature-summary-asset">{summaryObj.symbol.split('-')[1]} / {summaryObj.asset_name}</div>
                        <div className="feature-summary-transcript"><span className="feature-summary-date">Summary for {new Date(summaryObj.news_period).toString().slice(0,15)}</span>  &mdash; {summaryObj.summary.slice(0, 150) + '...'}</div>
                        <DemoPlayer url={summaryObj.url}/>
                    </div>
                }
            </div>
        </div>
    )
}
export default SectionDemoSummary
import React, { useState, useRef } from 'react';
import './playable_item.scss'

import { capitalize } from './utils';

const PlayableItem = ({item}) => { 
    const audioRef = useRef(null)
    const [loadPlayer, setLoadPlayer] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    // useEffect(() => {
    //     // set state is async, must check src is populated before calling play
    //     if (audioRef != null) return;
    //     audioRef.current.play()
    // }, [audioRef])

    function handlePlay(e) {
        if (!loadPlayer) { // loads layer on click instead of on first render
            setLoadPlayer(true);
            e.target.innerHTML = 'Pause'
            setIsPlaying(true)
        } else if (isPlaying) {
            audioRef.current.pause()
            e.target.innerHTML = 'Play'
            setIsPlaying(false)
        } else if (!isPlaying) {
            audioRef.current.play()
            e.target.innerHTML = 'Pause'
            setIsPlaying(true)
        }
    }

    return (
        <div className="playable-item" onClick={e => e.stopPropagation()}>
            <div className="playable-item-row">
                <div className="playable-item-name">{capitalize(item.frequency)} Update for {item.display_date}</div>
                <div className="playable-item-btns" onClick={e => handlePlay(e)}>Play</div>
                <a href={item.audio_url} 
                    className="playable-item-btns"
                    download={capitalize(item.frequency) + ' Update For ' + item.display_date}>Download</a>
            </div>
            {loadPlayer &&
                <div className="playable-item-drawer">
                    <audio ref={audioRef} autoPlay controls>
                        <source src={item.audio_url} type="audio/mpeg" />
                    </audio>
                </div>
            }
        </div>
    )
}

export default PlayableItem;
import React, { useState, useEffect, useRef } from 'react';
import PortfolioBox from './../components/portfolio_box'

// frequency in params is the view frequency
const ViewEdit = ({token, user, frequency}) => {
    const [assets, setAssets] = useState([]);
    const [templateTag, setTemplateTag] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [templateTier, setTemplateTier] = useState('');
    const [templateFrequency, setTemplateFrequency] = useState('');
    const [buttonLabel, setButtonLabel] = useState('Save') // for saving changes to portfolio

    const [config, setConfig] = useState({});

    // Needs to be in this scope, not config_free, else it won't update templateId
    let config_free = {
        type : 'free',
        limit : process.env.REACT_APP_FREE_LIMIT,
        content: `Free tier has a limit of ${process.env.REACT_APP_FREE_LIMIT} stocks. To add more, upgrade to a paid service. First month free, cancel anytime.`,
        buttonLabel: `Try for one month free`,
        buttonAction: async function (showMessage, template_id) {
            let price_id = (frequency == 'weekly') ? process.env.REACT_APP_PROD_ID_WEEKLY : process.env.REACT_APP_PROD_ID_DAILY;
            
            setButtonLabel('Loading...');
            showMessage(false);

            // requires user be authenticated, e.g. after account creation
            let res2 = await fetch(process.env.REACT_APP_API_ENDPOINT, {
                method: 'POST', 
                body: JSON.stringify({
                    intent: 'checkout.create', 
                    token: token,
                    stripe_customer_id: user.stripe_customer_id,
                    price_id : price_id,
                    template_id : template_id
                })
            });

            let data2 = await res2.json();
            window.location = data2.url;
        }
    }

    let config_paid = {
        type : 'paid',
        limit : process.env.REACT_APP_PAID_LIMIT, 
        content: `Our current limit is ${process.env.REACT_APP_PAID_LIMIT} stocks, if you'd like to add more you can submit a request`,
        buttonAction: function () {
            window.location.href = "mailto:support@heycadence.com?subject=Limit%20Increase%20Request&body=Hi,%20I%20would%20like%20to%20request%20a%20limit%20increase.";
        },
    }

    useEffect(async () => {
        if (!user) return;
        let url = `${process.env.REACT_APP_API_ENDPOINT}?intent=template.get&frequency=${frequency}&token=${token}`;

        let res = await fetch(url, {method: 'GET'})
        let data = await res.json()

        // console.log('subview edit ', data);

        setTemplateId(data.episode_templates.id);
        setTemplateTag(data.episode_templates.tag);
        setTemplateTier(data.episode_templates.tier);

        // somewhat redundant, episode frequency is the same as view frequency
        setTemplateTier(data.episode_templates.frequency);

        if (data.episode_templates.tier == 'free')  {
            setConfig(config_free);
        } else {
            setConfig(config_paid);
        }

        let formatted_data = data.episode_templates.assets.map(item => {
            return { s: item[0], n: item[1] }
        })
        setAssets(formatted_data);
    }, [])

    const onNext = (frequency, assets, tag) => {
        // console.log(assets);
        // console.log(token)

        let url = process.env.REACT_APP_API_ENDPOINT;
        let symbols = assets.map(item => item.s);
        // console.log(symbols);
        let payload = {
            token : token,
            intent: 'template.update',
            new_template : {
                id : templateId,
                frequency: frequency,
                symbols: symbols,
                tag: templateTag
            }
        }
        // console.log(payload);
        fetch(url, {method: 'POST', body: JSON.stringify(payload)})
        .then(res => res.json())
        .then(data => {
            if (data.status == 'success') {
                setButtonLabel('Save Successful!')
                setTimeout(() => {
                    setButtonLabel('Save')
                }, 1500)
            } else {
                setButtonLabel('Something Went Wrong')
                setTimeout(() => {
                    setButtonLabel('Save')
                }, 2000)
            }
        })
        .catch(e => {
            console.error(e);
        })
    }

    if (!user) return null;

    return (<>
        <PortfolioBox 
            searchPlaceholder="Add new stocks here"
            className="account" 
            loadAssets={assets} 
            loadFrequency={frequency}
            config={config}
            onNext={onNext}
            loadTemplateId={templateId}
            buttonLabel={buttonLabel} />
    </>)
}

export default React.memo(ViewEdit)
import React, { useState, useEffect, useRef } from 'react';
import { scroller } from 'react-scroll'

import PortfolioBox from './../components/portfolio_box'

const SectionDemoPortfolio = () => {
	const [assets, setAssets] = useState([]);
    const [buttonName, setButtonName] = useState('Save')
	const [frequency, setFrequency] = useState('');

	// const executeScroll = () => scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    useEffect(() => {
        try {
            let store_assets = window.localStorage.getItem('assets');
            let store_frequency = window.localStorage.getItem('frequency');
            store_assets = JSON.parse(store_assets)
            setAssets(store_assets)
            setFrequency(store_frequency)
        } catch {
            window.localStorage.removeItem('assets')
            window.localStorage.removeItem('frequency')
        }
    }, [])

    function onNext(frequency, assets, tag) {
        window.localStorage.setItem('frequency', frequency);
        window.localStorage.setItem('assets', JSON.stringify(assets));
        window.localStorage.setItem('tag', tag);
        setButtonName('Saved!')
    }

    const config = {
        type: 'demo', 
        limit: process.env.REACT_APP_PAID_LIMIT,
        content : `Hey you're basically done! Your selection will be saved when you create your account, and you can edit them at any time`,
        buttonLabel: `Ok`,
        buttonAction: function() {
            scroller.scrollTo('pricing', {
              duration: 400,
              smooth: true
            });
        }
    }

	return (
	<div className="section-feature">
		<div className="feature-descr portfolio">
			<div className="feature-try desktop portfolio"><span>Give it a try</span>&nbsp; <span className="material-icons-outlined fill-arrow">east</span></div>
            <div className="feature-header">Stay in the loop</div>
			<div className="feature-details">Add your portfolio once, and we will notify you when we detect news</div>
            <div className="feature-try mobile portfolio"><span className="material-icons-outlined fill-arrow">south</span>&nbsp; <span>Give it a try</span></div>
		</div>
		<PortfolioBox 
            className="demo"
            loadAssets={assets}
            searchPlaceholder="Add your stock here"
            loadFrequency={frequency}
            onNext={onNext} 
            isDemo={true}
            config={config}
            buttonLabel={buttonName}/>
	</div>
	)
}
export default SectionDemoPortfolio
